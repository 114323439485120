import CssBaseline from '@mui/material/CssBaseline'

import Stack from '@mui/material/Stack'
import Header from './header'
import Footer from './footer'

const Layout = (props) => (
    <>
        <CssBaseline />
        <Header />
        <Stack
            component='main'
            minHeight='70vh'
            height='auto'
        >
            {props.children}
        </Stack>
        <Footer />
    </>
)

export default Layout
