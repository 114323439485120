import { useEffect } from 'react'
import { Navigate, useNavigate, useLocation } from 'react-router-dom'
import { useIsAuthenticated } from '@azure/msal-react'

const ProtectedRoute = ({ children }) => {
    const isAuthenticated = useIsAuthenticated()
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        if (!isAuthenticated) navigate('/')
    }, [
        isAuthenticated,
        navigate,
    ])

    if (!isAuthenticated) {
        return <Navigate to='/signin' state={{ from: location }} replace />
    }

    return children
}

export default ProtectedRoute
