import { useMediaQuery } from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Text from '@mui/material/Typography'
import {  } from '@mui/material/colors'

// Importing different type of modals content
import CommonIframeModal from './common-iframe-modal'
import CarrierIframeModal from './carrier-iframe-modal'

const IframeModal = ({ link, open, onClose }) => {
    const match = useMediaQuery(theme => theme.breakpoints.up('md'))

    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: match ? '70vw' : '90vw',
                height: '90vh',
                bgcolor: 'background.paper',
                overflowY: 'auto',
            }}>
                {/* MODAL HEADER */}
                <Box
                    padding='1rem'
                    display='flex'
                    alignItems='center'
                    justifyContent='space-between'
                >
                    <Text
                        variant={match ? 'h5' : 'body1'}
                        fontWeight={501}
                        noWrap
                    >{link?.name}</Text>
                    <Button onClick={onClose} startIcon={<CloseIcon />}>Close</Button>
                </Box>

                {/* CONTENT MODAL */}
                <Box
                    padding='0.4rem'
                    height='90%'
                >
                    {link.modal === 'common' && <CommonIframeModal link={link} />}
                    {link.modal === 'carriers' && <CarrierIframeModal link={link} />}
                </Box>
            </Box>
        </Modal>
    )
}

export default IframeModal
