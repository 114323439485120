import { Navigate, useRoutes } from 'react-router-dom'

import App from './app'

import ProtectedRoute from 'components/protected-route'

// Importing pages
import DashboardPage from 'pages/dashboard-page'
import LinkesPage from 'pages/links-page'
import SignInPage from 'pages/sing-in-page'

const Router = () => {
    const element = useRoutes([
        {
            path: '/',
            element: <App />,
            children: [
                {
                    index: true,
                    element: (
                        <ProtectedRoute>
                            <DashboardPage />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: '/links/:departmentId',
                    element: (
                        <ProtectedRoute>
                            <LinkesPage />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: '/signin',
                    element: <SignInPage />,
                }
            ],
        },
        {
            path: '*',
            element: <Navigate to='/' />
        }
    ])

    return element
}

export default Router
