import env from 'config/env'

const items = [
	{
		name: "Unilinktransportations",
		image: `${env.hostMicroservices}/images/The-Yard-loco.svg`,
	},
	{
		name: "theyard1",
		image: `${env.hostMicroservices}/images/The-Yard-1.svg`,
	},
	{
		name: "theyard2",
		image: `${env.hostMicroservices}/images/The-Yard-2.svg`,
	},
	{
		name: "theyard3",
		image: `${env.hostMicroservices}/images/The-Yard-3.svg`,
	},
	{
		name: "theyard4",
		image: `${env.hostMicroservices}/images/The-Yard-4.svg`,
	},
]

export default items
