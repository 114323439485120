import Text from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import CopyToClipboardButton from './copy-clipboard-button'
import Divider from '@mui/material/Divider'

const properties = [
    {
        caption: 'Customer',
        name: 'project_customer',
        long: false,
    },
    {
        caption: 'Topic',
        name: 'topic',
        long: false,
    },
    {
        caption: 'Conclusion',
        name: 'conclusion',
        long: true,
    },
    {
        caption: 'Time schedule',
        name: 'time_schedule',
        long: false,
    },
    {
        caption: 'Callback',
        name: 'call_back',
        long: false,
    },
]

const CarrierCard = ({ carrier }) => (
    <Paper sx={{ mt: '1rem' }} elevation={7}>
        <Box
            display='flex'
            justifyContent='flex-end'
            pt='0.5rem'
            pr='0.5rem'
        >
            <Text variant='caption'>Created at: {carrier.call_date}</Text>
        </Box>
        <Divider />
        {
            properties.map((prop, index) => (
                <>
                    <Box
                        padding='0 0.5rem'
                        display='flex'
                        alignItems='center'
                        justifyContent='space-between'
                        key={index}
                    >
                        <Box display='flex' alignItems='baseline' width='100%'>
                            <Text variant='caption'>{prop?.caption}:</Text>
                            &nbsp;
                            <Text variant='body2' noWrap={!prop.long} maxWidth='180px'>{carrier[prop?.name]}</Text>
                        </Box>
                        <CopyToClipboardButton text={carrier[prop?.name]} />
                    </Box>
                    <Divider />
                </>
            ))
        }
    </Paper>
)

export default CarrierCard