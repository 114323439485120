import { useState } from "react"
import { IconButton, Snackbar } from "@mui/material"
import ShareIcon from "@mui/icons-material/Share"

const CopyToClipboardButton = ({ text }) => {
    const [open, setOpen] = useState(false)

    const handleClick = () => {
        setOpen(true)
        navigator.clipboard.writeText(text)
    }

    return (
        <>
            <IconButton onClick={handleClick} color="primary">
                <ShareIcon fontSize='small'/>
            </IconButton>
            <Snackbar
                message="Copied to clibboard"
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                autoHideDuration={2000}
                onClose={() => setOpen(false)}
                open={open}
            />
        </>
    )
}

export default CopyToClipboardButton
