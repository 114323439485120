import { useState } from 'react'

import IframeModal from './iframe-modal'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Text from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import ArrowsIcons from '@mui/icons-material/KeyboardDoubleArrowRight'
import LinkIcon from '@mui/icons-material/Link'

const GenericIcon = ({ link, isSublink }) => {
    const [modal, setModal] = useState(false)

    const handleClick = () => {
        if (link.type === 'modal') {
            setModal(link.modal)
            return
        }

        window.open(link?.url, '_blank')
    }

    return (
        <>
            {modal && (
                <IframeModal
                    open={modal}
                    onClose={() => setModal(false)}
                    link={link}
                />
            )}

            <Tooltip title={!link.url ? '' : (link?.tooltip || link?.name || '')}>
                <Paper
                    elevation={3}
                    onClick={handleClick}
                    sx={{
                        padding: '0.7rem',
                        width: '100%',
                        cursor: 'pointer',
                        display: 'flex',
                    }}
                >
                    <Box
                        width='100%'
                        textAlign='center'
                        padding='0 0.5rem'
                        display='flex'
                        alignItems='center'
                    >
                        {
                            isSublink
                                ? <ArrowsIcons fontSize='large' />
                                : <LinkIcon fontSize='large' />
                        }
                        <Text
                            variant='body2'
                            noWrap
                            ml='0.3rem'
                        >
                            {link.name}
                        </Text>

                    </Box>
                </Paper>
            </Tooltip>
        </>
    )
}

GenericIcon.defaultProps = {
    isSublink: false,
}

export default GenericIcon
