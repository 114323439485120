import { createElement } from 'react'
import { useNavigate } from 'react-router-dom'

import * as Icons from '@mui/icons-material'
import * as Colors from '@mui/material/colors'

import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Text from '@mui/material/Typography'
import OpenIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Remove'

const DepartmentCard = ({
    department,
    redirect = false,
    subdepartment = false,
    isOpen = false,
    onSelect = () => { },
}) => {
    const navigate = useNavigate()

    const handleClick = () => {
        if (redirect) {
            navigate(`/links/${department.id}`)
            return
        }
        onSelect(department.links)
    }

    return (
        <Paper
            onClick={handleClick}
            sx={{
                padding: '1rem',
                cursor: 'pointer',
                overflow: 'hidden',
                position: 'relative',
            }}
        >
            <Box
                left={0}
                bottom={0}
                width='100%'
                height='10px'
                position='absolute'
                backgroundColor={Colors[department?.color || 'red'][department?.colorTone || 500]}
            />

            <Stack
                direction={subdepartment ? 'row' : 'column'}
                alignItems='center'
                justifyContent='space-between'
            >
                <Stack
                    direction={subdepartment ? 'row' : 'column'}
                    alignItems='center'
                >
                    <Box sx={{ textAlign: 'center', mr: '0.5rem' }}>
                        {createElement(Icons[department?.icon || 'Mood'], {
                            fontSize: 'large',
                        })}
                    </Box>

                    <Box textAlign='center'>
                        <Text
                            noWrap
                            width='100%'
                            variant='body2'
                        >{department.name}</Text>
                    </Box>
                </Stack>

                {subdepartment && (
                    !isOpen ? <CloseIcon /> : <OpenIcon />
                )}
            </Stack>
        </Paper>
    )
}

export default DepartmentCard
