import { useState, useEffect } from 'react'
import axios from 'axios'
import Iframe from 'react-iframe'
import env from 'config/env'
import { useMediaQuery } from '@mui/material'
import { useMsal } from '@azure/msal-react'

import Text from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import CarrierCard from './carrier-card'

/**
 * Content when link.modal === 'carriers'
 * 
 * Make a division of window, one for the form, and the second
 * one for looking for carrier options.
 */
const CarrierIframeModal = ({ link }) => {
    const match = useMediaQuery(theme => theme.breakpoints.up('lg'))
    const { accounts, instance } = useMsal()

    //Carriers
    const [carriers, setCarriers] = useState(null)
    const [loadingCarrier, setLoadingCarrier] = useState(false)

    // Options
    const [options, setOptions] = useState([])
    const [loadingOptions, setLoadingOptions] = useState(false)

    const handleSelectCarrier = (_, selectedCarrier) => {
        const params = { name: selectedCarrier }

        if (selectedCarrier) {
            setLoadingCarrier(true)
            instance.acquireTokenSilent({ account: accounts[0] })
                .then(res => axios.get(`${env.queries}/api/ms/unlk-forms/v1/carriers`, {
                    params,
                    headers: { Authorization: `Bearer ${res.idToken}` }
                }))
                .then(res => setCarriers(res?.data?.carriers ?? []))
                .catch(err => setCarriers([]))
                .then(() => setLoadingCarrier(false))

            return
        }

        setCarriers(null)
    }

    useEffect(() => {
        setLoadingOptions(true)
        instance
            .acquireTokenSilent({ account: accounts[0] })
            .then(res => axios.get(`${env.queries}/api/ms/unlk-forms/v1/carriers`, {
                headers: { Authorization: `Bearer ${res.idToken}` },
            }))
            .then(axResponse => setOptions(axResponse.data?.carriers || []))
            .catch(err => setOptions([]))
            .then(() => setLoadingOptions(false))
    }, [
        accounts,
        instance,
        setOptions,
        setLoadingOptions,
    ])

    return (
        <Grid
            container
            spacing={2}
            height='100%'
        >
            <Grid
                item
                xs={12}
                lg={8}
            >
                <Iframe
                    url={link.url}
                    id={link.url}

                    display='initial'
                    position='relative'
                    width='100%'
                    height='100%'
                />
            </Grid>

            <Grid
                item
                xs={12}
                lg={4}
                height={match ? '100%' : '50%'}
            >
                <Box>
                    <Autocomplete
                        autoSelect
                        loading={loadingOptions}
                        options={options}
                        sx={{ width: '100%' }}
                        onChange={handleSelectCarrier}
                        renderInput={(params) => <TextField {...params} label='Carrier' />}
                    />
                </Box>

                {
                    loadingCarrier
                        ? (
                            <Box 
                                display='flex'
                                flexDirection='column'
                                justifyContent='center'
                                alignItems='center'
                                padding='1rem'
                            >
                                <Text>Loading...</Text>
                                <CircularProgress />
                            </Box>
                        ) : (
                            <Box
                                sx={{
                                    height: '85%',
                                    overflowY: 'scroll',
                                    padding: '0.5rem',
                                    mt: '1rem',
                                }}
                            >
                                {Array.isArray(carriers)
                                    ? carriers?.map((carrier, index) => <CarrierCard key={index} carrier={carrier} />)
                                    : <Text variant='caption' color='GrayText'>No carrier selected</Text>}
                            </Box>

                        )
                }

            </Grid>
        </Grid >
    )
}

export default CarrierIframeModal
