import { createTheme } from '@mui/material/styles'

// https://bareynol.github.io/mui-theme-creator/
const theme = createTheme({
	palette: {
		type: 'light',
		primary: {
			main: '#217fb7',
		},
		secondary: {
			main: '#c6202f',
		},
	},
})

export default theme
