export const data = [
	{
		id: 'support-services',
		name: 'Support Services',
		color: 'red',
		colorTone: 500,
		icon: 'SupportAgent',
		subdepartments: [
			{
				id: 'software',
				name: 'Software',
				icon: 'Terminal',
				color: 'red',
				colorTone: 300,
				links: [
					{
						id: 's-1',
						name: 'Installation Office 365',
						url: 'https://unilink.freshservice.com/support/catalog/items/3',
						type: 'redirect',
					},
					{
						id: 's-2',
						name: 'Pipdrive Account',
						url: 'https://unilink.freshservice.com/support/catalog/items/8',
						type: 'redirect',
					},
					{
						id: 's-3',
						name: 'Password Reset',
						url: 'https://unilink.freshservice.com/support/catalog/items/9',
						type: 'redirect',
					},
					{
						id: 's-4',
						name: 'Revoke Application Access',
						url: 'https://unilink.freshservice.com/support/catalog/items/29',
						type: 'redirect',
					},
					{
						id: 's-5',
						name: 'Data Backup',
						url: 'https://unilink.freshservice.com/support/catalog/items/6',
						type: 'redirect',
					},
					{
						id: 's-6',
						name: 'File Restore',
						url: 'https://unilink.freshservice.com/support/catalog/items/7',
						type: 'redirect',
					},
				],
			},
			{
				id: 'hardware',
				name: 'Hardware',
				icon: 'Computer',
				color: 'red',
				colorTone: 300,
				links: [
					{
						id: 'h-1',
						name: 'Maintenance Request',
						url: 'https://unilink.freshservice.com/support/catalog/items/20',
						type: 'redirect',
					},
				],
			},
		],
	},
	{
		id: 'operations',
		icon: 'SettingsSuggest',
		name: 'Operations',
		color: 'blue',
		colorTone: 500,
		links: [
			{
				id: 'op-1',
				url: 'https://app.klipfolio.com/published/afb569663151dcd6ca415ebab456fdab/2022-calls-stats',
				name: '2022-Calls Stats',
				type: 'redirect',
			},
			{
				id: 'op-2',
				url: 'https://unilink.freshservice.com/support/home#',
				name: 'Support Portal',
				type: 'redirect',
			},
			{
				id: 'op-3',
				name: 'Call Log by Carrier Procurement',
				url: 'https://formsapp.unitedlinktransportation.com/app/form?id=733DRw&t=0',
				type: 'modal',
				modal: 'carriers',
			},
			{
				id: 'op-4',
				name: 'CARRIERS – Extra Info',
				url: 'https://formsapp.unitedlinktransportation.com/app/form?id=TMGmFw',
				type: 'modal',
				modal: 'common',
			},
		],
	},
	{
		id: 'sales',
		icon: 'AttachMoney',
		name: 'Sales',
		color: 'amber',
		colorTone: 500,
		links: [
			{
				id: 'sales-1',
				name: '2022 Calls Stats',
				url: 'https://app.klipfolio.com/published/afb569663151dcd6ca415ebab456fdab/2022-calls-stats',
				type: 'redirect',
			},
			{
				id: 'sales-2',
				name: '2022 Calls Stats',
				url: 'https://app.klipfolio.com/published/722d12ed560b3ea9f05346756e082d0f/unlk-pipedrive',
				type: 'redirect',
			},
			{
				id: 'sales-3',
				name: '2022 Calls Stats',
				url: 'https://app.klipfolio.com/published/a38588b52048defcbf7c696cfbfec2fb/pipedrive-dashboard',
				type: 'redirect',
			},
		],
	},
	{
		id: 'pricing',
		name: 'Pricing',
		color: 'teal',
		colorTone: 500,
		icon: 'PointOfSale',
		links: [
			{
				id: 'pricing-1',
				name: 'Lane Pricing',
				url: 'https://unilink.freshservice.com/support/catalog/items/44',
				type: 'redirect',
			},
			{
				id: 'pricing-2',
				name: 'Customer Project',
				url: 'https://unilink.freshservice.com/support/catalog/items/45',
				type: 'redirect',
			},
			{
				id: 'pricing-3',
				name: 'Customer BID',
				url: 'https://unilink.freshservice.com/support/catalog/items/43',
				type: 'redirect',
			}
		],
	},
	{
		id: 'legal',
		name: 'Legal',
		color: 'brown',
		colorTone: 500,
		icon: 'Gavel',
		links: [
			{
				id: 'legal-1',
				name: 'Employee Verification',
				url: 'https://unilink.freshservice.com/support/catalog/items/19',
				type: 'redirect',
			}
		],
	},
	{

		id: 'marketing',
		name: 'Marketing',
		color: 'orange',
		colorTone: 500,
		icon: 'CameraEnhance',
		links: [
			{
				id: 'marketing-1',
				name: 'eMail Marketing Campaign',
				url: 'https://unilink.freshservice.com/support/catalog/items/28',
				type: 'redirect',
			},
			{
				id: 'marketing-2',
				name: 'Web Page Content',
				url: 'https://unilink.freshservice.com/support/catalog/items/27',
				type: 'redirect',
			},
		],
	},
	{
		id: 'hhrr',
		icon: 'People',
		name: 'HR Mgmt',
		color: 'indigo',
		colorTone: 500,
		links: [
			{
				id: 'hhrr-2',
				name: 'Employee On-boarding',
				url: 'https://unilink.freshservice.com/support/catalog/items/5',
				type: 'redirect',
			},
			{
				id: 'hhrr-3',
				name: 'Employee Off-boarding',
				url: 'https://unilink.freshservice.com/support/catalog/items/16',
				type: 'redirect',
			},
			{
				id: 'hhrr-4',
				name: 'Office Supplies',
				url: 'https://unilink.freshservice.com/support/catalog/items/25',
				type: 'redirect',
			},
			{
				id: 'hhrr-5',
				name: 'Guest Access',
				url: 'https://unilink.freshservice.com/support/catalog/items/24',
				type: 'redirect',
			},
			{
				id: 'hhrr-5',
				name: 'Role Description Input',
				url: 'https://formsapp.unitedlinktransportation.com/app/form?id=2lyEsw&t=0',
				type: 'modal',
				modal: 'common',
			}
		],
	},
]

export default data
