import { Helmet } from 'react-helmet'
import { Outlet } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'
import Layout from "components/layout"
import Container from '@mui/material/Container'
import { Box } from '@mui/system'

import env from 'config/env'

const App = () => {
	const match = useMediaQuery(theme => theme.breakpoints.up('md'))

	return (
		<>
			<Helmet>
				<link
					rel="stylesheet"
					href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
				/>
				<link
					rel="stylesheet"
					href="https://fonts.googleapis.com/icon?family=Material+Icons"
				/>
			</Helmet>
			<Box
				sx={{
					background: `url(${env.hostMicroservices}images/bg-white.svg)`,
					backgroundPosition: 'center',
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'cover',
					height: match ? '100vh' : '',
				}}
			>
				<Container
					maxWidth='xl'
				>
					<Layout>
						<Outlet />
					</Layout>
				</Container>
			</Box>
		</>
	)
}

export default App
