export const env = Object.freeze({
    /**
     * MICROSOFT CONFIG FOR LOGIN
     */
    ccaOptions: {
        auth: {
            clientId: process.env.REACT_APP_MS_CLIENT_ID,
            authority: `https://login.microsoftonline.com/${process.env.REACT_APP_MS_TENANT_ID}`,
            redirectUri: process.env.REACT_APP_HOST_APP,
        },
        cache: {
            cacheLocation: process.env.REACT_APP_CACHE_LOCATION,
            storeAuthStateInCookie: true,
        },
    },
    msalTokenRequest: {},
    queries: process.env.REACT_APP_HOST_QUERIES,
    host: process.env.REACT_APP_HOST,
    hostImages: process.env.REACT_APP_HOST_IMAGES,
    hostMicroservices: process.env.REACT_APP_HOST_MICROSERVICES,
    hostApp: process.env.REACT_APP_HOST_APP,
    queriesForms: 'https://yardnew.theyard.app:5000/api/ms/unlk-forms',
})

export default env
