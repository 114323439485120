import { useMediaQuery } from '@mui/material'

import Carousel from 'components/carousel'
import DepartmentCard from 'components/department-card'
import Grid from '@mui/material/Grid'

import data from 'data/data'

const DashboardPage = () => {
    const match = useMediaQuery(theme => theme.breakpoints.up('md'))

    return (
        <>
            {match && <Carousel />}

            <Grid
                container
                spacing={2}
                mt={match ? '0' : '1rem'}
            >
                {data.map((department, index) => (
                    <Grid
                        key={index}
                        item
                        xs={6}
                        sm={4}
                        md={2}
                    >
                        <DepartmentCard
                            department={department}
                            redirect
                        />
                    </Grid>
                ))}
            </Grid>
        </>
    )
}

export default DashboardPage
