import Paper from '@mui/material/Paper'
import Box from "@mui/material/Box"

const Item = ({ item }) => (
    <Paper sx={{ height: '200px' }}>
        <Box
            component='img'
            height='200px'
            width='100%'
            src={item?.image}
            alt={item?.name}
        />
    </Paper>
)

export default Item
