import { createElement } from 'react'

import Stack from '@mui/material/Stack'
import Link from '@mui/material/Link'
import Text from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import * as Icons from '@mui/icons-material'

import social from 'data/social'

const Footer = () => {
    return (
        <Stack
            position='relative'
            height={150}
            padding='0 1rem'
        >
            <Stack height='40%' />

            <Stack justifyContent='center'>
                <Link
                    underline='none'
                    href='https://unilinktransportation.com/'
                    target='_blank'
                    sx={{
                        width: '100%',
                        textAlign: 'center',
                    }}
                >
                    <Text>Copyright {new Date().getFullYear()} &copy; | Unilinktransportation</Text>
                </Link>
            </Stack>
            <Stack
                position='absolute'
                bottom={0}
                direction='row'
                spacing={1}
                justifyContent='center'
                width='100%'
            >
                {social.map((opt, indx) => (
                    <IconButton
                        key={indx}
                        color='primary'
                        size='large'
                        onClick={() => window.open(opt.link, '_blank')}
                    >
                        {createElement(Icons[opt?.icon ?? 'Mood'])}
                    </IconButton>
                ))}
            </Stack>
        </Stack>
    )
}

export default Footer
