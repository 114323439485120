const social = [
    {
        id: 'facebook',
        icon: 'Facebook',
        link: 'https://www.facebook.com/UNILINKTRANSPORTATION/',
    },
    {
        id: 'twitter',
        icon: 'Twitter',
        link: 'https://twitter.com/UnilinkT',
    },
    {
        id: 'instagram',
        icon: 'Instagram',
        link: 'https://www.instagram.com/unilink.transportation/',
    },
    {
        id: 'linkedin',
        icon: 'LinkedIn',
        link: 'https://www.linkedin.com/checkpoint/challenge/AgHDibs06PYCGQAAAYKh38yuz4zXQVsuJfJwPht_jFaYJFiIw0H2ryqr-nODsmfP_HKWmx52HXJNa89KCow_Bg4IFO3kNQ?ut=0ED0Af7aAXjGo1',
    },
    {
        id: 'youtube',
        icon: 'YouTube',
        link: 'https://www.youtube.com/user/UnilinkTransport',
    },
]

export default social
