import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'

import useMediaQuery from '@mui/material/useMediaQuery'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Text from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Avatar from '@mui/material/Avatar'
import Container from '@mui/material/Container'
import ListItemIcon from '@mui/material/ListItemIcon'
import { Divider } from '@mui/material'
// Importing icons
import Logout from '@mui/icons-material/Logout'

import env from 'config/env'

/**
 * MENU OPTIONS.
 */
const settings = [
    {
        id: 'logout',
        name: 'Logout',
        Icon: Logout,
    },
]

const Header = () => {
    const isAuthenticated = useIsAuthenticated()
    const matches = useMediaQuery((theme) => theme.breakpoints.up('md'))
    const { instance, accounts, } = useMsal()
    const navigate = useNavigate()

    const [anchorElUser, setAnchorElUser] = useState(null)

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget)
    }

    const handleCloseUserMenu = (setting) => {
        if (setting === 'logout') {
            instance.logoutPopup({
                account: accounts[0],
                mainWindowRedirectUri: env.host,
            })
        }

        setAnchorElUser(null)
    }

    return (
        <AppBar sx={{ backgroundColor: '#18314d' }} position='relative'>
            <Container maxWidth="xl">
                <Toolbar
                    disableGutters
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box
                        cursor='pointer'
                        onClick={() => navigate('/')}
                    >
                        <Box
                            component='img'
                            width={matches ? '200px' : '150px'}
                            src={`${env.hostImages}/yard-logo.svg`}
                        />
                    </Box>

                    <Box sx={{
                        flexGrow: 0,
                        display: 'flex',
                        alignItems: 'baseline',
                    }}
                    >
                        {isAuthenticated && (
                            <>
                                {
                                    matches && (
                                        <>
                                            Welcome,&nbsp;
                                            <Text sx={{
                                                fontWeight: '501',
                                                mr: '0.5rem'
                                            }}>
                                                {accounts[0].name}
                                            </Text>
                                        </>
                                    )
                                }

                                <Tooltip title="Open settings">
                                    <IconButton
                                        onClick={handleOpenUserMenu}
                                        sx={{ p: 0 }}
                                    >
                                        <Avatar
                                            alt={accounts[0]?.name}
                                            src="/static/images/avatar/2.jpg" />
                                    </IconButton>
                                </Tooltip>

                                <Box>
                                    <Menu
                                        sx={{ mt: '45px' }}
                                        id="menu-appbar"
                                        anchorEl={anchorElUser}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={Boolean(anchorElUser)}
                                        onClose={handleCloseUserMenu}
                                        PaperProps={{
                                            elevation: 0,
                                            sx: {
                                                overflow: 'visible',
                                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                mt: 1.5,
                                                '& .MuiAvatar-root': {
                                                    width: 32,
                                                    height: 32,
                                                    ml: -0.5,
                                                    mr: 1,
                                                },
                                                '&:before': {
                                                    content: '""',
                                                    display: 'block',
                                                    position: 'absolute',
                                                    top: 0,
                                                    right: 14,
                                                    width: 10,
                                                    height: 10,
                                                    bgcolor: 'background.paper',
                                                    transform: 'translateY(-50%) rotate(45deg)',
                                                    zIndex: 0,
                                                },
                                            },
                                        }}>
                                        <MenuItem>
                                            <Box sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                            }}>
                                                <Text>{accounts[0].name}</Text>
                                                <Text>{accounts[0].username}</Text>
                                            </Box>
                                        </MenuItem>

                                        <Divider />

                                        {
                                            settings.map((setting) => (
                                                <MenuItem
                                                    key={setting.id}
                                                    onClick={() => handleCloseUserMenu(setting.id)}
                                                >
                                                    <ListItemIcon>
                                                        <setting.Icon fontSize="small" />
                                                    </ListItemIcon>
                                                    <Text textAlign="center">{setting.name}</Text>
                                                </MenuItem>
                                            ))
                                        }
                                    </Menu>
                                </Box>
                            </>

                        )}
                    </Box>
                </Toolbar>
            </Container>
        </AppBar >
    )
}

export default Header
