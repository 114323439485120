import Iframe from 'react-iframe'

const CommonIframeModal = ({ link }) => (
    <Iframe
        url={link.url}
        id={link.url}
        display='initial'
        position='relative'
        width='100%'
        height='100%'
    />
)

export default CommonIframeModal
