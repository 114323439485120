import MUICarouser from 'react-material-ui-carousel'
import Item from './carousel-item'

import items from 'data/carousel-items'

const Carousel = () => (
    <MUICarouser
        fullHeightHover
        sx={{ my: '1rem' }}
        height='200px'
    >
        {items.map((item, i) => <Item key={i} item={item} />)}
    </MUICarouser>
)

export default Carousel
