import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { ThemeProvider } from '@mui/material'

import theme from 'config/theme'
import env from 'config/env'

import Routes from './routes'

/**
 * MICROSOFT REACT DOCS:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/tutorial-v2-react
 */
const msalInstance = new PublicClientApplication(env.ccaOptions)

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
	<MsalProvider instance={msalInstance}>
		<ThemeProvider theme={theme}>
			<BrowserRouter>
				<Routes />
			</BrowserRouter>
		</ThemeProvider>
	</MsalProvider>,
)
