import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'

import Box from '@mui/material/Box'
import Text from '@mui/material/Typography'
import Button from '@mui/material/Button'

import env from 'config/env'

const SignInPage = () => {
    const isAuthenticated = useIsAuthenticated()
    const navigate = useNavigate()
    const { instance } = useMsal()

    const handleLogin = () => {
        instance
            .loginPopup(env.msalTokenRequest)
            .catch(e => console.error(e))
    }

    useEffect(() => {
        if (isAuthenticated) navigate('/')
    }, [
        isAuthenticated,
        navigate,
    ])

    return (
        <Box
            sx={{
                position: 'relative',
                display: 'flex',
                height: '100%',
                mt: '1rem',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
            }}
        >
            <Text sx={{ mb: '1rem' }}>Get on board with your microsoft account!</Text>
            <Button
                variant='contained'
                onClick={() => handleLogin()}
            >Login with your MS account</Button>

            <Box
                component='img'
                alt='yard-logo'
                src={`${env.hostImages}/yard-logo.svg`}
                width='60%'
            />
        </Box>
    )
}

export default SignInPage
