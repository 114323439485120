import { useEffect, useState, createElement } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'

import * as Colors from '@mui/material/colors'
import * as Icons from '@mui/icons-material'

import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Text from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Link from 'components/link'
import DepartmentCard from 'components/department-card'
import Grid from '@mui/material/Grid'

import data from 'data/data'

const LinksPage = () => {
    const params = useParams()
    const navigate = useNavigate()
    const match = useMediaQuery(theme => theme.breakpoints.up('md'))

    const [department, setDepartment] = useState(null)
    const [subdep, setSubdep] = useState(null)
    const [links, setLinks] = useState(null)

    const handleSelect = (links, subdep) => {
        if (links && subdep) {
            setLinks(links)
            setSubdep(subdep)
            return
        }

        setSubdep(null)
        setLinks(null)
    }

    useEffect(() => {
        // Filter the department to get the arrays
        setDepartment(
            data.filter(dep => dep.id === params.departmentId)[0]
        )
    }, [
        params.departmentId,
        setDepartment,
    ])

    return (
        <>
            <Box
                margin='1rem 0'
                display='flex'
                flexDirection={match ? 'row' : 'column'}
                justifyContent='space-between'
                alignItems={match ? 'baseline' : 'center'}
            >
                <Button
                    color='primary'
                    sx={{ width: 200 }}
                    variant='outlined'
                    startIcon={createElement(Icons.KeyboardReturn)}
                    onClick={() => navigate('/')}
                >Go to dashboard</Button>

                <Stack
                    my='1rem'
                    alignItems='flex-start'
                    height={match ? '80px' : '60px'}
                    direction='row'
                    justifyContent='center'
                >
                    <Text
                        color={Colors.grey[900]}
                        borderBottom='10px solid'
                        borderTop='2px solid'
                        borderColor={Colors[department?.color || 'red'][department?.colorTone || 500]}
                        borderRadius='25px'
                        padding='0.4rem 1rem'
                        mb='0.5rem'
                        ml='1rem'
                        variant={match ? 'h4' : 'h5'}
                        display='flex'
                        alignItems='center'
                    >
                        {createElement(Icons[department?.icon || 'Mood'], {
                            fontSize: 'large'
                        })}
                        {department?.name || '---'}
                    </Text>
                </Stack>
            </Box>

            {/* WHEN A SUBDEPARTMENT IS SELECTED, SUBLINKS FROM THIS SUBDEPARMENT WILL BE DESPLAYED: */}
            {links && (
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <DepartmentCard
                            department={subdep}
                            subdepartment
                            onSelect={() => handleSelect(null)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            spacing={2}
                            container
                        >
                            {links.map(link => (
                                <Grid
                                    key={link.id}
                                    item
                                    xs={12}
                                    md={3}
                                >
                                    <Link
                                        isSublink
                                        link={link}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            )}

            {/* WHEN THERE ARE NOT SUBDEPARMENTS SELECTED OR THERE ARE JUST LINKS, DISPLAYED: */}
            {!links && (
                <Stack>
                    {department?.subdepartments?.length && (
                        <Grid
                            spacing={2}
                            container
                        >
                            {department.subdepartments.map(subdep => (
                                <Grid
                                    key={subdep.id}
                                    item
                                    xs={6}
                                    md={4}
                                    lg={3}
                                >
                                    <DepartmentCard
                                        key={subdep.id}
                                        department={subdep}
                                        subdepartment
                                        isOpen
                                        onSelect={(links) => handleSelect(links, subdep)}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    )}

                    {department?.links?.length && (
                        <Grid spacing={2} container>
                            {department.links.map(link => (
                                <Grid
                                    key={link.id}
                                    item
                                    xs={6}
                                    md={2}
                                >
                                    <Link link={link} />
                                </Grid>
                            ))}
                        </Grid>
                    )}
                </Stack>
            )}
        </>
    )
}

export default LinksPage
